@import "src/styles/variables";

.dialog {
  width: fit-content;
  // max-height: 1vh;
}

.form {
  // background-color: red;
  width: 40vh;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.row {
  display: flex;
  gap: 1.5rem;
  // margin-top: 0.75rem;
  // align-items: flex-start;
}

.bottomLabel {
  color: $main-gray;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}

.mapContainer {
  height: 250px;
  aspect-ratio: 1/1;
  // width: 5;
}

.list {
  display: flex;
  gap: 0.5rem;
}

.mapContainer {
  position: absolute;

  width: 250px;
  height: 250px;
  top: 0;
  right: calc(-1rem - 250px);

  padding: 1rem 1.25rem;
  border-radius: $border-radius;
  border: $card-border;
  background: $main-background;
}
