@import "src/styles/variables";

.dataTable {
  border-collapse: separate;
  border-spacing: 0;
  border: $header-border;
  border-radius: $border-radius;
  width: 100%;
  tbody,
  tr {
    td:first-child {
      text-align: left;
      width: max-content;
      white-space: nowrap;
      overflow: hidden;
    }

    border-radius: $border-radius;
  }
  td,
  th {
    border-left: $header-border;
    border-top: $header-border;
    text-align: center;
    padding: 0.1rem 0.25rem;
    font-family: "Lato", sans-serif;
    font-size: 0.75rem;
    color: $main-black;
    & input {
      background: transparent;
    }
  }
  th {
    position: relative;
    min-width: 140px;
    border-top: none;
    font-weight: 700;
    .subTitle {
      font-size: 0.65rem;
      opacity: 0.7;
    }
  }
  td:first-child,
  th:first-child {
    border-left: none;
  }
  tr:last-child {
    td:first-child {
      border-bottom-left-radius: $border-radius;
    }
    td:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }
  tr.mean {
    td,
    th {
      border-top: $main-stroke double;
    }
  }
}

.downloadContainer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  .chartToPngButton {
    padding: 2px;
    width: fit-content;
    height: fit-content;
    border-radius: 25%;
    background-color: transparent;
    color: black;
    cursor: pointer;
    transition: 10ms;
    &:active {
      transform: translateY(1px);
    }
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.help-icon {
  svg {
    height: 16px;
    width: 16px;
  }
  transform: translateY(-6px);
}

.chartTitle {
  width: max-content;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
  color: $main-black;
  margin-bottom: 15px;
}
