@import "src/styles/variables";

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #00000033;

  &.show {
    display: flex;
  }

  .modalContainer {
    width: 80%;
    height: 90%;
    background-color: $main-background;
    border-radius: $border-radius;
    border: $header-border;
    padding: 0.5rem;
    position: relative;
    z-index: 0;
  }

  .closeButtonContainer {
    all: unset;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1000;
    padding: 0.5rem;
    background-color: $main-background;
    border-radius: $border-radius;
    border: $header-border;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
